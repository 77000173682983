import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { storage } from "src/services/firebase";

const PDFUploader = ({path, onFinishedUpload}) => {

    const [isUploading, setIsUploading] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.type === "application/pdf") {
            const storageRef = storage.ref(path);
            const uploadTask = storageRef.put(file);

            setIsUploading(true);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // Optionally handle progress here
                },
                (error) => {
                    console.error("Upload error:", error);
                    setIsUploading(false);
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        setIsUploading(false);
                        onFinishedUpload(file.name, downloadURL)
                    });
                }
            );
        } else {
            alert("Only PDF files are allowed!");
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: "application/pdf" });


    return (
        <Box
            sx={{
                height: 200,
                border: "2px dashed #1976d2",
                borderRadius: 2,
                padding: 3,
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: isDragActive ? "#f0f8ff" : "#fafafa",
            }}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            {isUploading ? (
                <CircularProgress />
            ) : (
                <Box>
                    <Typography sx={{mt: 5}} variant="h6" color="textSecondary">
                        {isDragActive ? "Drop the PDF here..." : "Drag and drop a PDF file here or click to upload"}
                    </Typography>
                    <Button variant="contained" sx={{ mt: 2, width: 160 }}>
                        Upload PDF
                    </Button>
                </Box>
            )}
            
        </Box>
    );
};

export default PDFUploader;
