import {
    LIST_PRESCRIPTIONS_SUCCESS,
    LIST_PRESCRIPTIONS_FAILURE,
    LIST_PRESCRIPTIONS_REQUEST,
    UPDATE_PRESCRIPTION_FAILURE,
    UPDATE_PRESCRIPTION_SUCCESS,
    UPDATE_PRESCRIPTION_REQUEST,
    GET_PRESCRIPTION_FAILURE,
    GET_PRESCRIPTION_SUCCESS,
    GET_PRESCRIPTION_REQUEST
} from "../actions/adminPrescriptions"

const defaultState = {
    //reports
    isPrescriptionsUpdating: false,
    isPrescriptionsLoading: false,
    prescriptions: [],
    listPrescriptionError: '',
    getPrescriptionError: '',
    updatePrescriptionError: '',
    didPrescriptionsUpdatePass: null,
    didPrescriptionsUpdateFail: null
}

const adminPrescriptions = (state = defaultState, action) => {
    switch (action.type) {
        case LIST_PRESCRIPTIONS_SUCCESS:
            return {
                ...state,
                prescriptions: action.data,
                isPrescriptionsLoading: false,
            }
        case LIST_PRESCRIPTIONS_FAILURE:
            return {
                ...state,
                isPrescriptionsLoading: false,
                listPrescriptionError: action.message
            }
        case LIST_PRESCRIPTIONS_REQUEST:
            return {
                ...state,
                isPrescriptionsLoading: true,
            }
        case GET_PRESCRIPTION_SUCCESS:
            return {
                ...state,
                prescriptions: action.data,
                isPrescriptionsLoading: false,
            }
        case GET_PRESCRIPTION_FAILURE:
            return {
                ...state,
                isPrescriptionsLoading: false,
                getPrescriptionError: action.message
            }
        case GET_PRESCRIPTION_REQUEST:
            return {
                ...state,
                isPrescriptionsLoading: true,
            }
        case UPDATE_PRESCRIPTION_FAILURE:
            return {
                ...state,
                isPrescriptionsUpdating: false,
                didPrescriptionsUpdatePass: false,
                didPrescriptionsUpdateFail: true,
                updatePrescriptionError: action.message
            }
        case UPDATE_PRESCRIPTION_SUCCESS:
            return {
                ...state,
                isPrescriptionsUpdating: false,
                didPrescriptionsUpdatePass: true,
                didPrescriptionsUpdateFail: false,
                updatePrescriptionError: ''
            }
        case UPDATE_PRESCRIPTION_REQUEST:
            return {
                ...state,
                isPrescriptionsUpdating: true,
                didPrescriptionsUpdatePass: false,
                didPrescriptionsUpdateFail: false,
                updatePrescriptionError: ''
            }
        default:
            return state
    }
}

export default adminPrescriptions