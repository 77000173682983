//get PRESCRIPTIONS
export const LIST_PRESCRIPTIONS_REQUEST = 'LIST_PRESCRIPTIONS_REQUEST'
export const listPrescriptionsRequest = () => {
    return {
        type: LIST_PRESCRIPTIONS_REQUEST, 
    }
}
export const LIST_PRESCRIPTIONS_SUCCESS = 'LIST_PRESCRIPTIONS_SUCCESS'
export const listPrescriptionsSuccess = data => {
    return {
        type: LIST_PRESCRIPTIONS_SUCCESS,
        data
    }
}

export const LIST_PRESCRIPTIONS_FAILURE = 'LIST_PRESCRIPTIONS_FAILURE'
export const listPrescriptionsFailure = (message) => {
    return {
        type: LIST_PRESCRIPTIONS_FAILURE,
        message
    }
}

//get PRESCRIPTIONS
export const GET_PRESCRIPTION_REQUEST = 'GET_PRESCRIPTION_REQUEST'
export const getPrescriptionRequest = () => {
    return {
        type: GET_PRESCRIPTION_REQUEST, 
    }
}
export const GET_PRESCRIPTION_SUCCESS = 'GET_PRESCRIPTION_SUCCESS'
export const getPrescriptionSuccess = data => {
    return {
        type: GET_PRESCRIPTION_SUCCESS,
        data
    }
}

export const GET_PRESCRIPTION_FAILURE = 'GET_PRESCRIPTION_FAILURE'
export const getPrescriptionFailure = (message) => {
    return {
        type: GET_PRESCRIPTION_FAILURE,
        message
    }
}

//update PRESCRIPTION
export const UPDATE_PRESCRIPTION_REQUEST = 'UPDATE_PRESCRIPTION_REQUEST';
export const updatePrescriptionRequest = () => {
    return {
        type: UPDATE_PRESCRIPTION_REQUEST,
    };
}
export const UPDATE_PRESCRIPTION_SUCCESS = 'UPDATE_PRESCRIPTION_SUCCESS';
export const updatePrescriptionSuccess = data => {
    return {
        type: UPDATE_PRESCRIPTION_SUCCESS,
        data
    };
}
export const UPDATE_PRESCRIPTION_FAILURE = 'UPDATE_PRESCRIPTION_FAILURE';
export const updatePrescriptionFailure = (message) => {
    return {
        type: UPDATE_PRESCRIPTION_FAILURE,
        message
    };
}