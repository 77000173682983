

import React from 'react'
//redux
import { connect } from 'react-redux';
//ui
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

//custom
import PlotImage from 'src/components/reports/vsba/PlotImage';
import PageHeader from './PageHeader';

const parseCategory = (category) => {

    if (category === 'COMMERCIALIZATION: Category of Processes' || category === 'COMMERCIALIZATION') {
        return 'Commercialization'
    } else {
        return category
    }
}

const MaturityPlotPage = (props) => {

    const {
        finalEvaluation,
        assessorData,
        quadSequence,
        axisSequence
    } = props;

    //variables
    const classes = useStyles();
    const firstName = assessorData.assessor.name ? assessorData.assessor.name : ''
    const companyName = assessorData.company.name ? assessorData.company.name : ''

    //calculate overall average
    const calculateOverallAverage = () => {
        let total = 0;
        let average = 0;

        try {
            finalEvaluation.forEach(category => {
                total = total + category.average;
            })
        } catch (e) {
            console.log('calculateOverallAverage error: ', e.message)
        }

        average = total / 4;

        return average;
    }

    //returns cabality message based on list item index
    const displayCapability = (indexOrder) => {

        let capabilityTxt = '';

        const message = [
            {
                quad: 'Q1',
                message: 'Capability to Develop an Offering'
            }, {
                quad: 'Q2',
                message: 'Capability to Respond to Market Demand'
            }, {
                quad: 'Q3',
                message: 'Capability to Generate Revenue'
            }, {
                quad: 'Q4',
                message: 'Capability to Establish a Business Model'
            }
        ]

        try {
            message.forEach(element => {
                if (quadSequence[indexOrder] == element.quad) {
                    capabilityTxt = element.message;
                }
            })

        } catch (e) {
            console.log('displayCapability error: ', e.message)
        }

        return capabilityTxt;
    }

    //get average score for category
    const getScore = (category) => {
        let score = 0;

        try {
            finalEvaluation.forEach(element => {
                if (element.category.toUpperCase() == category.toUpperCase()) {
                    score = element.average;
                }
            })
        } catch (e) {
            console.log('getScore error: ', e.message)
        }

        //add 2 decimals if none
        if (score.toString().length === 1) {
            score = score.toFixed(2)
        }

        return score;
    }

    //get category zone
    const getZone = (category) => {

        let zone = '';

        try {
            finalEvaluation.forEach(element => {
                if (element.category.toUpperCase() == category.toUpperCase()) {
                    if (element.average >= 2) {
                        zone = 'Foundational'
                    } else if (element.average >= 1) {
                        zone = 'Developmental'
                    } else if (element.average < 1) {
                        zone = 'Premature'
                    }
                }
            })
        } catch (e) {
            console.log('getZone error: ', e.message)
        }

        return zone;
    }

    //get indicator for categroy improvement
    const showSelection = (category) => {

        let indicator = '';

        //console.log(axisSequence)
        try {
            finalEvaluation.forEach(element => {
                //match category to lowest ranking axis
                if (category.toUpperCase() === element.category.toUpperCase() && element.axis == axisSequence[3][0]) {
                    indicator = '<--- Improve this Category 1st.'
                }
            })
        } catch (e) {
            console.log('showSelection error: ', e.message)
        }

        return indicator;
    }

    return (
        <>
            {/* page header */}
            <PageHeader />
            <Box mt={4}>
                <Typography gutterBottom variant="h2">
                    Your Capabilities Maturity Plot
                </Typography>
            </Box>

            {/* graphic */}
            <Box mt={2}>
                <PlotImage />
            </Box>

            {/* score */}
            <Box mt={6} ml={8} className={classes.row} id="overallScore">
                <Typography gutterBottom variant="h5">
                    {calculateOverallAverage()}
                </Typography>
                <Typography gutterBottom variant="h5" ml={2}>
                    Overall Average Assessment Rating (out of 3.0)
                </Typography>
            </Box>

            {/* capability rank */}
            <Box mt={1} >
                <TableContainer>
                    <Table id="capabilityRankTable">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ borderBottom: "2px solid black", width: '180px' }}>
                                    <Typography color='black' variant="h4" >Quadrant Sizes:</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "2px solid black", width: '420px' }}>
                                    <Typography color='black' variant="h4" >Capability Rank</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            <TableRow sx={{ height: 1 }}>
                                <TableCell sx={{ borderBottom: "none", textAlign: 'right' }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}> Strongest: </Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{displayCapability(0)}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}><Typography ></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: "none", textAlign: 'right' }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>2nd:</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{displayCapability(1)}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}><Typography ></Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19, textAlign: 'right' }}>3rd:</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{displayCapability(2)}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}><Typography ></Typography></TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell sx={{ borderBottom: "1px solid black" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19, textAlign: 'right' }}>Most Vulnerable:</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "1px solid black" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{displayCapability(3)}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="h5">{'<--- Improve this Capability 1st.'}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* category name */}
            <Box mt={2}>
                <TableContainer>
                    <Table id="categoryScoreTable">
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ borderBottom: "2px solid black", width: '190px' }}>
                                    <Typography color='black' variant="h4" >Category Scores:</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "2px solid black", width: '205px' }}>
                                    <Typography color='black' variant="h4" >Category Name</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "2px solid black", width: '205px' }}>
                                    <Typography color='black' variant="h4" >Category Zone</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            <TableRow sx={{ height: 1 }}>
                                <TableCell sx={{ borderBottom: "none", textAlign: 'right' }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>{getScore('Product')}</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>Product</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{getZone('Product')}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="h5">{showSelection('Product')}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: "none", textAlign: 'right' }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>{getScore('Market')}</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>Market</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{getZone('Market')}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="h5">{showSelection('Market')}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: "none", textAlign: 'right' }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>{getScore('Business')}</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>Business</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{getZone('Business')}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="h5">{showSelection('Business')}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19, textAlign: 'right' }}>{getScore('Commercialization')}</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}>Commercialization</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="body1" style={{ fontSize: 19 }}><i>{getZone('Commercialization')}</i></Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                    <Typography color='black' variant="h5">{showSelection('Commercialization')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

const useStyles = makeStyles({
    centeredColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center'
    },
    splitRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    flexStart: {
        display: 'flex',
        alignItems: 'flex-start',
        alignSelf: 'flex-start'
    },
    brownBanner: {
        borderBottom: '2px solid black',
        borderTop: '2px solid black',
        backgroundColor: '#DDD9C4',
    },
});

const mapStateToProps = state => ({
    assessorData: state.takingAssessment.assessorData,
    finalEvaluation: state.takingAssessment.finalEvaluation,
    quadSequence: state.takingAssessment.quadSequence,
    axisSequence: state.takingAssessment.axisSequence,
})

export default connect(mapStateToProps)(MaturityPlotPage);
