import { PDFDocument, rgb, PageSizes } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import calibriFontPath from '../../../assets/calibri-regular.ttf';
import moment from 'moment';

import { storage } from 'src/services/firebase';

// Utility function for adding header
const addHeader = (pages, customFont, date, company, assessor) => {
    pages.forEach((page) => {
        const { width, height } = page.getSize();

        page.drawText(`${moment.unix(date).format('MM/DD/YYYY')}`, {
            x: width / 2 - 35,
            y: height - 64,
            font: customFont, // Ensure this is a PDFFont
            size: 10,
        });

        page.drawText(`Assessor: ${assessor}`, {
            x: width - 200,
            y: height - 55,
            font: customFont, // Ensure this is a PDFFont
            size: 10,
        });

        page.drawText(`Company: ${company}`, {
            x: width - 200,
            y: height - 66,
            font: customFont, // Ensure this is a PDFFont
            size: 10,
        });
    });
};

// Utility function for adding notes
const addNotes = (lastPage, customFont, notes) => {
    const { width, height } = lastPage.getSize();


    lastPage.drawText(notes, {
        x: 50,
        y: height - 100, // Adjust as necessary
        size: 11,
        font: customFont, // Ensure this is a PDFFont
        color: rgb(0, 0, 0), // Black text
        maxWidth: width - 100, // Wrap text within the page margins
        lineHeight: 14,
    });
};

export const createPrescriptionPDF = async (dynamicContent) => {
    if (!dynamicContent) {
        console.error('No content provided to generate the PDF.');
        return;
    }

    try {

        const pdfRef = storage.ref(`prescriptions/${dynamicContent.name}/${dynamicContent.selection}/content.pdf`);
        // Get the download URL
        const fileUrl = await pdfRef.getDownloadURL();

        console.log("fileUrl: ", fileUrl)

        const existingPdfBytes = await fetch(fileUrl).then((res) => res.arrayBuffer());
        const fontBytes = await fetch(calibriFontPath).then((res) => res.arrayBuffer());

        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit);

        const customFont = await pdfDoc.embedFont(fontBytes);

        pdfDoc.addPage(PageSizes.Letter);

        const pages = pdfDoc.getPages();
        const lastPage = pages[pages.length - 1];

        addHeader(pages, customFont, dynamicContent.dateAssessed, dynamicContent.organization, dynamicContent.assessor);
        addNotes(lastPage, customFont, dynamicContent.notes);

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `Prescription-${dynamicContent.name}.pdf`;
        link.click();
        window.URL.revokeObjectURL(link.href);

    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};
