import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, List, Typography } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';

import NavItem from '../NavItem';

import {
    Home as HomeIcon,
    BookOpen as BookOpenIcon,
    Edit as EditIcon,
    UserPlus as UserPlusIcon,
    CreditCard as CreditCardIcon,
    Send as SendIcon,
    Book as BookIcon,
    ShoppingBag as ShoppingBagIcon,
    Mail as MailIcon,
    HelpCircle as HelpCircleIcon,
    UserCheck as UserCheckIcon,
    DollarSign as DollarIcon
} from 'react-feather';

const DashboardSidebar = ({ onMobileClose, onChange, openMobile, userInfo }) => {
    const location = useLocation();

    const navigation = useSelector(state => state.auth.navigation)

    const [isHeatMap, setIsHeatMap] = useState(false)

    const firstName = userInfo.name ? userInfo.name.first : '';
    const lastName = userInfo.name ? userInfo.name.last : '';
    const title = userInfo.company ? userInfo.company.title : '';
    const email = userInfo.email ? userInfo.email : '';
    const uid = userInfo.id ? userInfo.id : '';

    const items = [
        {
            href: '/app/mypanel',
            icon: HomeIcon,
            title: 'My Panel',
            header: 'My Panel',
            divider: true
        },
        {
            href: '/app/help',
            icon: HelpCircleIcon,
            title: 'FAQ',
            header: 'Help',
        },
        {
            href: 'https://business.vectorreports.com/How%20To%20.../',
            icon: BookIcon,
            title: 'How To...',
            external: true
        },
        {
            href: 'https://business.vectorreports.com/glossary/',
            icon: BookIcon,
            title: 'Glossary',
            external: true
        },
        {
            href: '/app/guide',
            icon: BookIcon,
            title: 'Success Guide',
            header: 'Guide',
            divider: true
        },
        {
            href: '/app/take',
            icon: EditIcon,
            title: 'Take',
            header: 'Action Page: Take Assessment',
            enabled: !navigation[0]
        },
        {
            href: '/app/view',
            icon: BookOpenIcon,
            title: 'View / Print',
            header: 'Action Page: View Reports',
            enabled: !navigation[1]
        },
        {
            href: '/app/assign',
            icon: UserPlusIcon,
            title: 'Assign Assessor',
            header: 'Action Page: Assign Assessor',
            enabled: !navigation[2]
        },
        {
            href: '/app/transfer',
            icon: SendIcon,
            title: 'Transfer Ownership',
            header: 'Action Page: Transfer Assessment Ownership',
            enabled: !navigation[3],
            divider: true
        },
        {
            href: '/app/buy',
            icon: DollarIcon,
            title: 'Buy Assessment',
            header: 'Buy Assessment',
        },
    ];

    const heatMapSideBar = [
        {
            href: '/app/mypanel',
            icon: HomeIcon,
            title: 'My Panel',
            header: 'My Panel',
            divider: true
        }
    ]

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }

        if(location.pathname === '/app/heatmap') {
            setIsHeatMap(true)
        } else {
            setIsHeatMap(false)
        }

    }, [location.pathname]);


    const content = (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2, py: 4 }} >
                <Typography color="textPrimary" variant="h5" >
                    {firstName}{' '}{lastName}
                </Typography>
                <Typography color="textSecondary" variant="body2" >
                    {title}
                </Typography>
                <Typography color="textSecondary" variant="body2" >
                    {email}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <List>
                    {!isHeatMap && items.map((item) => (
                        <>
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                loadData={item.loadData}
                                enabled={item.enabled}
                                external={item.external}
                            />
                            {item.divider && <Divider />}
                        </>
                    ))}
                    {isHeatMap && heatMapSideBar.map((item) => (
                        <>
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                loadData={item.loadData}
                                enabled={item.enabled}
                                external={item.external}
                            />
                            {item.divider && <Divider />}
                        </>
                    ))}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left" onClose={onMobileClose} open={openMobile}
                    variant="temporary" PaperProps={{ sx: { width: 256 } }}
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer anchor="left" open variant="persistent"
                    PaperProps={{ sx: { width: 256, top: 64, height: 'calc(100% - 64px)' } }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps)(DashboardSidebar);
