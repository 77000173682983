import React, { useState, useEffect } from "react";
import { CircularProgress, Box, Typography } from "@mui/material";

import {
    CheckCircle as CheckCircleIcon,
    AlertCircle as AlertIcon
} from 'react-feather';

import { storage } from "src/services/firebase";

const FileChecker = ({ filePath, uploadedFile }) => {
    const [fileExists, setFileExists] = useState(null); // null = loading, true = exists, false = not exists

    useEffect(() => {
        const checkIfFileExists = async () => {
            const fileRef = storage.ref(filePath);

            try {
                await fileRef.getMetadata(); // Check metadata
                setFileExists(true); // File exists
            } catch (error) {
                if (error.code === "storage/object-not-found") {
                    setFileExists(false); // File does not exist
                } else {
                    console.error("Error checking file:", error);
                }
            }
        };

        if(uploadedFile) {
            setFileExists(true)
        } else {
            checkIfFileExists();
        }
       
    }, [filePath, uploadedFile]);

    return (
        <Box display="flex" alignItems="center">
            {fileExists === null ? (
                <CircularProgress size={20} />
            ) : fileExists ? (
                <CheckCircleIcon color="green" size={22} />
            ) : (
                <AlertIcon color="red" size={22} />
            )}
            <Typography sx={{ ml: 1 }}>
                {fileExists === null
                    ? "Checking..."
                    : fileExists
                        ? "File exists"
                        : "File does not exist"}
            </Typography>
        </Box>
    );
};

export default FileChecker
