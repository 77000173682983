import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, List, Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import NavItem from '../NavItem';
import moment from 'moment';

import {
    List as ListIcon,
    FileText as BookOpenIcon,
} from 'react-feather';

const DashboardSidebar = ({ onMobileClose, openMobile, userInfo }) => {
    const location = useLocation();

    const firstName = userInfo.name ? userInfo.name.first : '';
    const lastName = userInfo.name ? userInfo.name.last : '';
    const title = userInfo.company ? userInfo.company.title : '';
    const email = userInfo.email ? userInfo.email : '';
    const uid = userInfo.id ? userInfo.id : '';

    const items = [ 
        {
            href: '/admin/assessment-content',
            icon: ListIcon,
            title: 'Edit Assessment Content',
        },
        {
            href: '/admin/access-codes',
            icon: BookOpenIcon,
            title: 'Manage Access Codes',
        },
        {
            href: '/admin/reports',
            icon: BookOpenIcon,
            title: 'View Reports',
        },
        {
            href: '/admin/templates',
            icon: BookOpenIcon,
            title: 'Email Templates',
        },
      ];

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);
    // let copy = React.string({js|©|js});
    const content = (
        <Box sx={{  display: 'flex', flexDirection: 'column', height: '100%', borderTop: '1px solid #DCDCDC'}}  >
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', p: 2, py: 4 }} >
                <Typography color="textPrimary" variant="h5" >
                    {firstName}{' '}{lastName} 
                </Typography>
                <Typography color="textSecondary" variant="body2" >
                    {title}
                </Typography>
                <Typography color="textSecondary" variant="body2" >
                    {email}
                </Typography>
            </Box>
            <Box sx={{ p: 2, borderTop: '1px solid #DCDCDC' }}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            loadData={item.loadData}
                        />
                    ))}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }} mb={1}>
                <Typography color="textPrimary" variant="body2">Copyright Vector Reports © {moment().format('YYYY')}</Typography>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer anchor="left" onClose={onMobileClose} open={openMobile}
                        variant="temporary" PaperProps={{ sx: { width: 256 } }} 
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer anchor="left" open variant="persistent"
                        PaperProps={{ sx: { width: 256, top: 64, height: 'calc(100% - 64px)' } }}
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
    onMobileClose: () => { },
    openMobile: false
};

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps)(DashboardSidebar);
