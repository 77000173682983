import React, { useState, useEffect, useRef } from 'react'
//redux
import { connect } from 'react-redux';
import { getAdminReports, updateAdminReports } from 'src/redux/thunks';
//ui
import { Helmet } from 'react-helmet-async';
import { CircularProgress, Box, Alert, Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Container, Paper, ButtonGroup, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
//components
import EditAssessmentReport from 'src/components/admin/EditAssessmentReport';
import TableSpinner from 'src/components/shared/TableSpinner';
import EditPrescriptionReport from 'src/components/admin/EditPrescriptionReport';

const AssessmentList = (props) => {

    const {
        getAdminReports,
        updateAdminReports,
        reports,
        isReportsLoading,
        didReportUpatePass,
        isUpdatingReport,
        didReportUpdateFail,
    } = props;

    // const [assessment, setAssessment] = useState(null);
    const [assessmentID, setAssessmentID] = useState(null)
    const [openPrescriptionEditor, setOpenPrescriptionEditor] = useState(false)
    const [openReportEditor, setOpenReportEditor] = useState(false)
    const [didReportChange, setDidReportChange] = useState(false)

    const pageOpenRef = useRef(0)
    const previousOpenRef = useRef(0)

    const classes = useStyles();

    useEffect(() => {
        getAdminReports()
    }, [])

    // useEffect(() => {
    //     console.log('----- reports modified ', pageOpenRef.current, previousOpenRef.current)
    //     // if (pageOpenRef.current > 1 && pageOpenRef.current == previousOpenRef.current){
    //     if (pageOpenRef.current > 1 && pageOpenRef.current != previousOpenRef.current && !isUpdatingReport) { 
    //         alert('Attention - Changes have been made')
    //         previousOpenRef.current = pageOpenRef.current;
    //         // setDidReportChange(true)
    //         // const changeTimeout = setTimeout(() => {
    //         //     setDidReportChange(false)
    //         //     clearTimeout(changeTimeout)
    //         // }, 5000)
    //     } else {
    //         pageOpenRef.current = pageOpenRef.current + 1;
    //     }
    // },[reports])

    //open report editor
    const handleClick = (row, index, type) => {
        // todo figure out how to use state in report component so it modifies onsnapshot
        // setAssessment(row)
        setAssessmentID(index)
        if (type === 'prescription') {
            setOpenPrescriptionEditor(true)
        } else {
            setOpenReportEditor(true)
        }
    }

    const updateReport = async (content, reportId) => {
        //setLoad(true)
        await updateAdminReports(content, reportId)
        setOpenReportEditor(false)
    }


    const closeEditor = () => {
        setOpenReportEditor(false)
    }

    if (openPrescriptionEditor) {
        return (
            <EditPrescriptionReport
                handleBack={() => setOpenPrescriptionEditor(false)}
                handleSave={() => setOpenPrescriptionEditor(false)}
            />
        )
    }

    if (openReportEditor) {
        return (
            <EditAssessmentReport
                assessment={reports[assessmentID]}
                handleSave={updateReport}
                handleBack={closeEditor} />
        )
    }

    return (
        <>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='md' >
                    <Typography color="textPrimary" variant="h2" >
                        Assessments
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2" >
                        Edit report content for an assessment below
                    </Typography>
                </Container>
                {/* Table */}
                <Box mt={4}>
                    <Container maxWidth='md'>
                        <Box mb={2}>
                            {isUpdatingReport && (
                                <Box className={classes.center}>
                                    <CircularProgress />
                                </Box>
                            )}
                            {didReportUpatePass && (
                                <Alert severity="success">Success — Report content updated</Alert>
                            )}
                            {didReportUpdateFail && (
                                <Alert severity="error">Error - Report content did not update</Alert>
                            )}
                            {/* {(didReportChange) && (
                            <Alert severity="warning">Attention - Changes have been made</Alert>
                        )} */}
                        </Box>
                    </Container>
                    <Container maxWidth='md'>
                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <TableContainer sx={{ pl: 1.2 }}>
                                <Table aria-label="purchaseTable" size={'medium'}>
                                    {/* Table Header - Sort */}
                                    <TableHead>
                                        <TableRow >
                                            {
                                                headCells.map((headCell) => {
                                                    return (
                                                        <TableCell key={headCell.id}>
                                                            {headCell.label}
                                                        </TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {/* Table Body */}
                                    <TableBody>
                                        {reports && reports.length > 0 ? (
                                            reports.map((row, index) => {
                                                const key = `purchase-${index}`;
                                                return (
                                                    <TableRow key={key}>
                                                        <TableCell>{row.reportType}</TableCell>
                                                        <TableCell>{row.reportName}</TableCell>
                                                        <TableCell sx={{ display: 'flex', justifyContent: 'end' }}>
                                                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                                                {row.reportId === 'via-audit' && <Button onClick={() => handleClick(row, index, 'prescription')}>Edit Prescription</Button>}
                                                                <Button onClick={() => alert('This will open asssessment contet editor')}>Edit Content</Button>
                                                                <Button onClick={() => handleClick(row, index, 'report')} color="secondary">Edit Report</Button>
                                                                {/* <Button>Three</Button> */}
                                                            </ButtonGroup>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <TableSpinner isLoading={isReportsLoading} colSpan={headCells.length} />
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </>
    )
}

const headCells = [
    { id: 'type', numeric: false, label: 'Type' },
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'action', numeric: false, label: '' },
];

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', width: '100%'
    }
});

const mapStateToProps = state => ({
    reports: state.adminReports.reports,
    isReportsLoading: state.adminReports.isReportsLoading,
    didReportUpatePass: state.adminReports.didReportUpatePass,
    isUpdatingReport: state.adminReports.isUpdatingReport,
    didReportUpdateFail: state.adminReports.didReportUpdateFail,
});

const mapDispatchToProps = dispatch => ({
    getAdminReports: () => dispatch(getAdminReports()),
    updateAdminReports: (data, id) => dispatch(updateAdminReports(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentList);

